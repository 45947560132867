import { Component, Input, OnInit } from '@angular/core';
import { Entity } from '@proman/services/entity.service';
import { FilterService } from '@proman/services/filter.service';
import moment from 'moment';
import * as echarts from 'echarts';
import { CurrenciesService } from '@proman/services/currencies.service';
import { Store } from '@ngrx/store';
import { getSystemOptions } from '@proman/store/system-options';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { FrontendDirectivesModule } from '../../shared/frontend-directives.module';
import { CommonModule } from '@angular/common';
import { InputsModule } from '../../inputs/inputs.module';

interface Datum { year: string, month: string, amount: number }
interface Dataset { income: Datum[], invoice: Datum[], purchase: Datum[], salary: Datum[], tax: Datum[] }

@Component({
  selector: 'pm-income-report',
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    FrontendDirectivesModule,
    InputsModule,
  ],
  template: `
    @if (mini) {
      <ng-container *ngTemplateOutlet="miniGraph"></ng-container>
    } @else {
      <div fxLayout="column">
        <div fxLayout="column"
             pmPadding>
          <div fxLayout="column">
            <div fxLayout="row"
                 fxLayoutAlign="start bottom">
              <pm-monthpicker [value]="dataConfig.from"
                              [config]="{ label: 'from' }"
                              (onChange)="updatePeriod('from', $event)"></pm-monthpicker>
              <pm-monthpicker [value]="dataConfig.to"
                              [config]="{ label: 'to' }"
                              (onChange)="updatePeriod('to', $event)"></pm-monthpicker>
            </div>

            <div id="chart" style="height: 400px;"></div>
          </div>
        </div>
      </div>
    }
    
    <ng-template #miniGraph>
      <div id="chart" style="height: 10vh"></div>
    </ng-template>
  `
})

export class IncomeReportComponent implements OnInit {
  @Input() mini: boolean = false;
  dataConfig: { from: string, to: string };
  data: Dataset;
  accountingReportEntity: any;
  dataTypes: string[] = ['income', 'invoice', 'purchase', 'salary', 'tax'];
  defaultDecimal: number;
  timeData: Set<string>;

  constructor(
    private Entity: Entity,
    private Filter: FilterService,
    private Currency: CurrenciesService,
    private store: Store,
  ) {
    this.store.select(getSystemOptions).subscribe((value) => this.defaultDecimal = value.defaultDecimalPlaces);
    this.accountingReportEntity = this.Entity.get({ name: 'accounting_report', post: ['incomeReport'] }) as unknown as any;
    this.dataConfig = { from: moment().subtract(1, 'year').format('YYYY-MM'), to: moment().format('YYYY-MM') };
  }

  ngOnInit() {

    this.loadData();
  }

  loadData = () => {
    interface ResponseData {
      year: string,
      month: string,
      sumLocalAmount: string,
      type: string,
    }

    this.accountingReportEntity.incomeReport({ from: this.dataConfig.from, to: this.dataConfig.to }).then((response: ResponseData[]) => {
      this.timeData = new Set();
      const data: Dataset = { income: [], invoice: [], purchase: [], salary: [], tax: [] };

      response.forEach((datum) => {
        data[datum.type].push({ year: datum.year, month: this.Filter.translate(datum.month), amount: parseFloat(datum.type !== 'invoice' ? `-${datum.sumLocalAmount}` : datum.sumLocalAmount).toFixed(this.defaultDecimal) });
        this.timeData.add(`${datum.year} - ${this.Filter.translate(datum.month)}`);
      })

      this.timeData.forEach((set) => {
        const [year, month] = set.split(' - ');
        data.income.push({ year, month, amount: 0 })
      })

      Object.entries(data).forEach(([key, value]) => {
        if (key === 'income') return;

        value.forEach((item: Datum) => {
          const periodItem = data.income.find((pi) => pi.year == item.year && pi.month == item.month);
          if (periodItem) periodItem.amount += +item.amount;
        })
      })

      data.income.forEach(income => income.amount = Number(parseFloat(`${income.amount}`).toFixed(this.defaultDecimal)));
      console.log('data', data);
      console.log('timeData', this.timeData);
      this.data = data;

      this.drawChart();
    })
  };

  updatePeriod = (property: string, value: string) => {
    this.dataConfig[property] = value;

    this.loadData();
  };

  drawChart() {
    type EChartsOption = echarts.EChartsOption;
    const translatedTypes = [this.Filter.translate(this.dataTypes[0]), this.Filter.translate(this.dataTypes[1]), this.Filter.translate(this.dataTypes[2]), this.Filter.translate(this.dataTypes[3]), this.Filter.translate(this.dataTypes[4])];

    const chartDom = document.getElementById('chart')!;
    const myChart = echarts.init(chartDom);
    const colors = ['#990000', '#3bdb3b', '#93d8cf', '#f78ff2', '#d39f4c'];
    const option: EChartsOption = {
      color: colors,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      legend: {
        data: translatedTypes
      },
      xAxis: [
        {
          type: 'category',
          data: Array.from(this.timeData),
        }
      ],
      yAxis: {
        type: 'value',
        name: this.Filter.translate('value'),
        position: 'right',
        alignTicks: true,
        axisLabel: {
          formatter: '{value} ' + this.Currency.base?.shorthand
        }
      },
      series: [],
    };

    option.series = translatedTypes.map((type, index) => ({
      name: type,
      type: index ? 'bar' : 'line',
      stack: index ? 'total' : 'none',
      data: this.data[this.dataTypes[index]].map((datum: Datum) => ({ value: datum.amount })),
    }))

    if (this.mini) {
      option.series = {
        name: this.Filter.translate(this.dataTypes[0]),
        type: 'line',
        stack: 'none',
        data: this.data[this.dataTypes[0]].map((datum: Datum) => ({ value: datum.amount })),
      }

      option.yAxis = {
        type: 'value',
        position: 'right',
        tooltip: null,
        axisLabel: { show: false },
        splitLine: { show: false },
      };

      option.xAxis[0].axisLabel = { show: false };
      option.tooltip = null;
      option.legend = null;
      option.color = colors[1];
    }

    option && myChart.setOption(option);
  }
}
